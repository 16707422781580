import { useEffect } from "react";

// thanks to young-ha
const useAspectRatio = () => {
    const updateAspectRatio = () => {
        const vw = window.innerWidth;
        const vh = window.innerHeight;

        if (vh > vw) {
            document.documentElement.style.setProperty("--bs-aspect-ratio-width", `${vh / 1500}`);
            document.documentElement.style.setProperty("--bs-aspect-ratio-height", `${vw / 400}`);
        } else {
            document.documentElement.style.setProperty("--bs-aspect-ratio-width", `${vw / 1512}`);
            document.documentElement.style.setProperty("--bs-aspect-ratio-height", `${vh / 982}`);
        }
    };

    useEffect(() => {
        const isMobile = window.innerHeight > window.innerWidth;

        if (isMobile) {
            // 모바일 환경에서는 한 번만 실행
            updateAspectRatio();
        } else {
            // 데스크톱 환경에서는 resize 이벤트 감지
            updateAspectRatio();
            window.addEventListener("resize", updateAspectRatio);
        }

        return () => {
            if (!isMobile) {
                window.removeEventListener("resize", updateAspectRatio);
            }
        };
    }, []);
};

export default useAspectRatio;